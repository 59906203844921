<template>
  <div>
    <section class="py-5">
      <div class="container">
        <div class="d-flex align-items-start">
          
          <div class="aiz-user-sidenav-wrap position-relative z-1 shadow-sm">
            <div class="aiz-user-sidenav rounded overflow-auto c-scrollbar-light pb-5 pb-xl-0">
              <div class="p-4 text-xl-center mb-4 border-bottom bg-primary text-white position-relative">
                <span class="avatar avatar-md mb-3">
                  <img :src="userInfo.Photo ? `${userInfo.Photo}` : headerUrl" class="image rounded-circle" >
                </span>
                <div class="h5 fs-16 mb-1 fw-600">{{ userInfo.FullName }}</div>
                <div class="text-truncate opacity-60" v-if="userInfo.Phone">{{ userInfo.Phone }}</div>
                <div class="text-truncate opacity-60" v-else>{{ userInfo.Email }}</div>
              </div>

              <div class="sidemnenu mb-3">
                <ul class="aiz-side-nav-list px-2" data-toggle="aiz-side-menu">
                  <li class="aiz-side-nav-item"  @click="toPath('dashboard')">
                    <a class="aiz-side-nav-link ">
                      <i class="las aiz-side-nav-icon la-home" ></i>
                      <span class="aiz-side-nav-text">{{ $t('shou-ye') }}</span>
                    </a>
                  </li>  
                  <template >
                    <li class="aiz-side-nav-item" v-for="(item, i) in menuList2" :key="i" @click="toPath(item.path, item.query)">
                      <a class="aiz-side-nav-link ">
                        <i class="las la aiz-side-nav-icon" :class="item.icon"></i>
                        <span class="aiz-side-nav-text">
                          {{item.name}}
                          <span class="red-point" v-if="item.point && messageCount > 0"></span>
                        </span>
                      </a>
                    </li>                
                  </template>
                </ul>
              </div>

            </div>

            <div class="fixed-bottom d-xl-none bg-white border-top d-flex justify-content-between px-2" style="box-shadow: 0 -5px 10px rgb(0 0 0 / 10%);">
              <a class="btn btn-sm p-2 d-flex align-items-center" >
                  <i class="las la-sign-out-alt fs-18 mr-2"></i>
                  <span>{{ $t('tui-chu') }}</span>
              </a>
              <button class="btn btn-sm p-2 " >
                <i class="las la-times la-2x"></i>
              </button>
            </div>
          </div>

          <router-view></router-view> 
        </div>
      </div>
    </section>

 
</div>

</template>
<script>
import { userInfo } from '@/api/user'
import { unReadCount } from '@/api/seller'
export default {
  data() {
    return {
      showLeftMenu: true,
      headerUrl: require('../../assets/imgs/avatar-place.png'),
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    },
    messageCount() {
      return this.$store.state.messageCount
    },
    menuList2() {
      return [
        {
          icon: 'la-file-alt',
          name: this.$t('gou-mai-li-shi'),
          path: 'purchase_history'
        },
        {
          icon: 'la-download',
          name: this.$t('xia-zai-0'),
          path: 'download'
        },
        {
          icon: 'la-backward',
          name: this.$t('yi-fa-song-tui-kuan-qing-qiu'),
          path: 'refund'
        },
        {
          icon: 'la-heart-o',
          name: this.$t('yuan-wang-qing-dan'),
          path: 'wishlist'
        },
        {
          icon: 'la-comment',
          name: this.$t('dui-hua'),
          path: 'conversations',
          point: true
        },
        {
          icon: 'la-dollar-sign',
          name: this.$t('wo-de-qian-bao'),
          path: 'wallet'
        },
        {
          icon: 'la-atom',
          name: this.$t('gong-dan-0'),
          path: 'ticket'
        },
        {
          icon: 'la-atom',
          name: this.$t('mei-ri-ren-wu'),
          path: 'order'
        },
        {
          icon: 'la-atom',
          name: this.$t('ren-wu-ji-lu'),
          path: 'orderList'
        },
        {
          icon: 'la-wallet',
          name: this.$t('jiao-yi-mi-ma-0'),
          path: 'safePassword',
        },
        {
          icon: 'la-user',
          name: this.$t('guan-li-ge-ren-zi-liao'),
          path: 'userInfo'
        },
      ]
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      unReadCount().then(res => {
        this.$store.state.messageCount = res.data
      })
    },
    toPath(path, type) {
      if (path) {
        this.$router.push({
          name: path,
          query: {
            type
          }
        })
      }
    },
  }
}
</script>